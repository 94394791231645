import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ClipboardModule } from 'ngx-clipboard';

import { ReportsRoutingModule } from './reports-routing.module';
import { ComponentsModule } from 'src/app/components/components.module';
import { ReportsLandingComponent } from './component/reports-landing/reports-landing.component';
import { ReportsLandingAnonymousComponent } from './component/reports-landing-anonymous/reports-landing-anonymous.component';
import { ReportDetailsPanelComponent } from './component/report-details-panel/report-details-panel.component';
import { ReportDetailsAnonymousComponent } from './component/report-details-anonymous/report-details-anonymous.component';
import { InitScanComponent } from './component/init-scan/init-scan.component';
import { InitScanAnonymousComponent } from './component/init-scan-anonymous/init-scan-anonymous.component';
import { ReportsListComponent } from './component/reports-list/reports-list.component';
import { ReportCreatedComponent } from './component/report-created/report-created.component';
import { CreateAccessCodeComponent } from './component/create-access-code/create-access-code.component';
import { NzPaginationModule } from 'ng-zorro-antd';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { ReportImportExportService } from './services/report-import-export.service';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    ReportsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    ClipboardModule,
    NzPaginationModule,
    NzAutocompleteModule,
    NzPopconfirmModule,
    NzModalModule,
    NzUploadModule,
    NzToolTipModule
  ],
  declarations: [
    ReportsLandingComponent,
    ReportsLandingAnonymousComponent,
    ReportsListComponent,
    ReportDetailsPanelComponent,
    ReportDetailsAnonymousComponent,
    InitScanComponent,
    InitScanAnonymousComponent,
    ReportCreatedComponent,
    CreateAccessCodeComponent,
  ],
  providers: [
    ReportImportExportService
  ]
})
export class ReportsModule { }
